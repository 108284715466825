.section-title{
  font-size: 24px;
  width: 90%;
  text-align: left;
  margin: 40px auto 0 auto;
}
.main-account-row td{
  background-color: greenyellow !important;
}
.data-table-header{
  width: 90%;
  margin: 15px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .data-table-search-box{
    max-width: 350px;
    display: flex;
    input{
      margin-right: 10px;
    }
  }
  .database-header-actions{
    display: flex;
    align-items: center;
  }
  .data-table-action-buttons{
    margin-left: 10px;
    min-width: fit-content;
  }
}
.table{
  margin-bottom: 0 !important;
}
.data-table-container{
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  margin: 50px auto 0 auto;
  border: 1px solid #1e1f21;
  thead{
    background: #1e1f21 !important;
    tr{
      background: #1e1f21 !important;
    }
    th{
      background: #1e1f21 !important;
      color: white;
      border-right: 1px solid white;
      font-size: 16px;
      padding: 5px 0;
      &:last-child{
        border-right: none;
      }
    }
  }
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.pagination-container span {
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
}
.pagination-container .active-page-number {
  color: #1ED8B9;
}
.actions-dropdown{
  cursor: pointer;
  font-size: 20px;
}
.actions-list{
  list-style-type: none;
  font-size: 15px;
  li{
    padding: 5px 20px;
    cursor: pointer;
    &:hover{
      background: #0a56f7;
      color: white;
    }
  }
}
.actions-dropdown-box{
  position: absolute;
  background: white;
  padding: 8px 0;
  box-shadow: 0 0 8px 0 gray;
  border-radius: 8px;
}
.actions-list{
  padding: 0;
  margin: 0;
}
.no-padding{
  padding: 0 !important;
}
.data-table-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.select-filters{
  display: flex;
  align-items: center;
}